import { useContext, createContext } from "react";
import * as mobx from "mobx";
import process from "process";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  process.env.PUBLIC_URL = "http://localhost:3000";
  console.log("dev :: ", process.env.PUBLIC_URL);
} else {
  // production code
  // process.env.PUBLIC_URL = "https://datahive-proj.web.app";
  process.env.PUBLIC_URL = "https://aisodata.co.kr";
  // process.env.PUBLIC_URL = "http://localhost:5000";
  console.log("production :: ", process.env.PUBLIC_URL);
}

const context = createContext(
  mobx.observable({
    menuState: "/user/dataAnalysis",
    sidebarOpen: true,

    /* 마켓 페이지 정보 저장 */
    aisoMarketPage: 1,
    /* 데이터 분석 탭 저장 */
    dataAnalysisSwitchPage: "all",

    /* 앱 관리 탭 저장 */
    myAppTab: "dataAnalysis",

    // 회원 정보
    login: false,
    email: "",
    name: "",
    photo: "",

    // Type 정보
    customerServiceType: "notice",
  })
);

const useSettings = () => {
  return useContext(context);
};

export default useSettings;
