import React from "react";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="w-full bg-white">
      <div className="visitor-footer w-full h-full pt-20 pb-14">
        <div className="w-full flex justify-center sm:justify-between">
          <div>
            <img className="w-24" src="/images/visitor/footer_logo.png" alt="" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between sm:items-end">
          <div className="text-333 mt-4">
            <div className="mt-4 flex flex-col justify-center items-center sm:justify-start sm:items-start keep-words text-center">
              <p className="text-sm font-gmarket-medium">안양시 동안구 시민대로 327번길 11-41 안양창업지원센터 613호</p>
              <div className="md:flex">
                <div className="sm:flex items-center">
                  <p className="text-sm font-gmarket-medium whitespace-nowrap"> 대표 : 성기범</p>
                  <p className="hidden px-2.5 sm:inline">|</p>
                  <p className="text-sm font-gmarket-medium whitespace-nowrap"> 개인정보 보호책임자 : 문현정</p>
                  <p className="hidden px-2.5 sm:inline">|</p>
                  <p className="text-sm font-gmarket-medium whitespace-nowrap"> 사업자등록번호 : 119-86-45421</p>
                </div>
                <div className="items-center">
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1198645421"
                    className="btn btn-outline-gray text-xs py-1 px-3 mb-2 ml-3 whitespace-nowrap"
                  >
                    사업자 정보확인
                  </a>
                </div>
              </div>
              <div className="sm:flex items-center">
                <p className="text-sm font-gmarket-medium whitespace-nowrap"> 대표 전화 : (070) 8813-6229</p>
                <p className="hidden px-2.5 sm:inline">|</p>
                <p className="text-sm font-gmarket-medium whitespace-nowrap"> 통신판매업신고번호 : 제 2021-안양동안-1423호</p>
              </div>
              <p className="text-sm font-gmarket-medium"> E-Mail : cs@enkino.com</p>
            </div>
          </div>
          {/* <div className="text-muted flex justify-content-center justify-content-sm-end mt-4 mt-sm-0 text-center">
            <div className="site-icon-group row-between mt-2">
              <a className="site-circle-icon bg-gray-300 hover:bg-gray-700">
                <i className="ti-facebook" />
              </a>
              <a className="site-circle-icon bg-gray-300 hover:bg-gray-700">
                <i className="ti-youtube" />
              </a>
              <a className="site-circle-icon bg-gray-300 hover:bg-gray-700">
                <i className="mdi mdi-earth" />
              </a>
            </div>
          </div> */}
        </div>
        <div className="center pt-20">
          <div className="flex">
            <Link
              href={{
                pathname: "/user/terms",
                query: { id: "privacyStatement" },
              }}
            >
              <p className="text-sm font-gmarket-medium hover:underline cursor-pointer">개인정보처리방침</p>
            </Link>
            <span className="px-2.5">|</span>
            <Link
              href={{
                pathname: "/user/terms",
                query: { id: "termsOfService" },
              }}
            >
              <p className="text-sm font-gmarket-medium hover:underline cursor-pointer">이용표준약관</p>
            </Link>
          </div>

          <div className="sm:flex text-sm font-gmarket-light font-bold">
            <span className="pt-1"> Copyright © 2021</span>
            <a href="http://www.enkino.com/" target="_blank" rel="noopener noreferrer" className="pt-1 px-2 text-333 font-gmarket-medium">
              Enkino.
            </a>
            <span className="pt-1">All rights reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
