import React from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import useLayoutEffect from "function/use-isomorphic-layout-effect";
import ScrollToTop from "components/scrollArrow";

const UserLayout = ({ children }) => {
  // Warning: useLayoutEffect does nothing on the server 제거용
  useLayoutEffect(() => {
    console.log("hello there");
  }, []);

  return (
    <div className="container-scroller">
      <UserHeader />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel-user">
          <div className="content-wrapper-user">
            <main className="h-full">{children}</main>
          </div>
          <ScrollToTop />
          <UserFooter />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
