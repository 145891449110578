/** @format */

import React from "react";
import DevHeader from "./DevHeader";
import Sidebar from "./Sidebar";
import DevFooter from "./DevFooter";
import ScrollToTop from "components/scrollArrow";

const DevLayout = ({ children }) => {
  return (
    <div className="w-full h-screen relative" id="document-html">
      <div className="container-scroller">
        <DevHeader />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            {/* <div className={`${router.pathname.startsWith("/myWork") ? "content-wrapper-mywork" : "content-wrapper"} `}> */}
            <main className="h-full">
              <div className="content-wrapper-mywork">{children}</div>
            </main>
            <ScrollToTop />
            <DevFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevLayout;
