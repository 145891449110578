import React from "react";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="w-full visitor-footer-bg">
      <div className="visitor-footer w-full h-full py-7">
        <div className="hidden sm:flex items-center flex-col lg:flex-row md:items-start">
          <p className="text-sm font-gmarket-medium">안양시 동안구 시민대로 327번길 11-41 안양창업지원센터 613호</p>
          <p className="hidden px-2.5 lg:inline">|</p>
          <div className="flex flex-col items-center sm:flex-row sm:items-start">
            <p className="text-sm font-gmarket-medium whitespace-nowrap"> 대표 : 성기범</p>
            <p className="hidden px-2.5 sm:inline">|</p>
            <p className="text-sm font-gmarket-medium whitespace-nowrap"> 대표 전화 : (070) 8813-6229</p>
            <p className="hidden px-2.5 sm:inline">|</p>
            <p className="text-sm font-gmarket-medium whitespace-nowrap"> E-Mail : cs@enkino.com</p>
          </div>
        </div>
        <div className="hidden sm:flex items-center flex-col lg:flex-row md:items-start">
          <div className="flex flex-col items-center sm:flex-row sm:items-start">
            <Link
              href={{
                pathname: "/user/terms",
                query: { id: "privacyStatement" },
              }}
            >
              <p className="text-sm font-gmarket-medium hover:underline cursor-pointer">개인정보처리방침</p>
            </Link>
            <span className="px-2.5">|</span>
            <Link
              href={{
                pathname: "/user/terms",
                query: { id: "termsOfService" },
              }}
            >
              <p className="text-sm font-gmarket-medium hover:underline cursor-pointer">이용표준약관</p>
            </Link>
            <p className="hidden px-2.5 md:inline">|</p>

            <p className="text-sm font-gmarket-medium whitespace-nowrap"> 통신판매업신고번호 : 제 2021-안양동안-1423호</p>
            <p className="hidden px-2.5 lg:inline">|</p>
          </div>
          <div className="flex">
            <p className="text-sm font-gmarket-medium whitespace-nowrap"> 사업자등록번호 : 119-86-45421</p>
            <a
              href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1198645421"
              className="btn aiso-button-in text-xs py-1 px-3 mb-2 ml-3 whitespace-nowrap"
            >
              사업자 정보확인
            </a>
          </div>
        </div>
        <div className="w-full flex items-center flex-col lg:flex-row md:items-start">
          <div className="flex text-sm font-gmarket-light font-bold">
            <span className="pt-1"> Copyright © 2021</span>
            <a href="http://www.enkino.com/" target="_blank" rel="noopener noreferrer" className="pt-1 px-2 text-black font-gmarket-medium">
              Enkino.
            </a>
            <span className="pt-1">All rights reserved.</span>
          </div>

          <p className="hidden px-2.5 lg:inline mt-1.5">|</p>

          <div className="flex text-sm font-gmarket-light font-bold">
            <span className="pt-1"> Image copyright_</span>
            <a
              href="https://www.freepik.com"
              target="_blank"
              rel="noopener noreferrer"
              className="pt-1 px-2 text-black font-gmarket-medium"
            >
              Designed by Freepik
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
