import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.FIREBASE_CONFIG_MEASUREMENT_ID,
};

const Logout = async (settings) => {
  settings.login = false;
  settings.email = "";
  settings.name = "";
  window.localStorage.removeItem("datahiveToken");

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  const auth = firebase.auth();
  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
      console.log("로그아웃");
    })
    .catch((error) => {
      // An error happened.
      console.log("로그아웃 에러 : ", error);
    });
};
export default Logout;
