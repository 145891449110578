import React from "react";
import swal from "@sweetalert/with-react";

export const BasicAlert = (title) => {
  // Swal.fire({
  //   title: "Error!",
  //   text: "Do you want to continue",
  //   icon: "error",
  //   confirmButtonText: "Cool",
  // });
  return swal(<div className="font-bold text-sm">{title}</div> || "Error", {
    button: false,
    timer: 2000,
  });
};

export const LongAlert = (title) => {
  return swal(<div className="font-bold text-sm">{title}</div> || "Error", {
    button: false,
    timer: 5000,
  });
};

export const SuccessAlert = (title) => {
  return swal(
    (
      <div className="font-bold text-sm">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <div className="mt-4">{title}</div>
        </div>
      </div>
    ) || "Error",
    {
      button: false,
      timer: 3000,
    }
  );
};
