/** @format */

import React, { useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
// Material Menu Icon
import StorefrontRoundedIcon from "@material-ui/icons/StorefrontRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import VibrationOutlinedIcon from "@material-ui/icons/VibrationOutlined";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";

const Sidebar = observer(() => {
  const router = useRouter();
  const settings = useSettings();

  /* 사이드바의 메뉴를 Collapse(접었다 폈다)로 표현하고 싶을 때 사용 */
  useEffect(() => {
    settings.menuState = router.pathname;
  }, [router.pathname]);

  /* 헤더의 grid 아이콘 클릭 시 사이드바에 아이콘만 표시되도록 css 적용. 
    아래 코드는 아이콘만 표시되었을 때 마우스가 아이콘 위로 올라가면 해당하는 메뉴가 보이도록 
    css를 변경하는 코드.*/
  useEffect(() => {
    document.querySelector("#sidebar").classList.remove("active");
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });

      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [settings.sidebarOpen]);

  /* 관리자 메뉴 */
  if (router.pathname.startsWith("/admin"))
    return (
      <nav className="sidebar sidebar-offcanvas fixed" id="sidebar">
        <ul className="nav">
          {/* 아이소 마켓 페이지로 가기 */}
          <li className={`nav-item ${router.pathname.startsWith("/user/dataAnalysis") ? "active" : ""}`}>
            <Link href="/user/dataAnalysis">
              <a className="nav-link bg-primary  rounded-sm flex items-center">
                <i className="menu-icon flex justify-center text-white">
                  <StorefrontRoundedIcon />
                </i>
                <span className="menu-title text-white font-bold">아이소 마켓으로</span>
              </a>
            </Link>
          </li>
          {/* 홈 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/home") ? "active" : ""}`}>
            <Link href="/admin/home">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <HomeRoundedIcon />
                </i>
                <span className="menu-title">Home</span>
              </a>
            </Link>
          </li>
          {/* 수익 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/aisoProfitSetting") ? "active" : ""}`}>
            <Link href="/admin/aisoProfitSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <TrendingUpRoundedIcon />
                </i>
                <span className="menu-title font-bold">AISO 수익</span>
              </a>
            </Link>
          </li>
          {/* 정산 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/calculate") ? "active" : ""}`}>
            <Link href="/admin/calculate">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <ReceiptOutlinedIcon />
                </i>
                <span className="menu-title">정산 관리</span>
              </a>
            </Link>
          </li>
          {/* 문의 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/inquireSetting") ? "active" : ""}`}>
            <Link href="/admin/inquireSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <ChatOutlinedIcon />
                </i>
                <span className="menu-title">문의 관리</span>
              </a>
            </Link>
          </li>
          {/* 신고 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/reportSetting") ? "active" : ""}`}>
            <Link href="/admin/reportSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <ReportOutlinedIcon />
                </i>
                <span className="menu-title">신고 관리</span>
              </a>
            </Link>
          </li>
          {/* 한줄 긋기 */}
          <li>
            <hr className="mx-1" />
          </li>
          {/* 앱관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/appSetting") ? "active" : ""}`}>
            <Link href="/admin/appSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <VibrationOutlinedIcon />
                </i>
                <span className="menu-title">앱 관리</span>
              </a>
            </Link>
          </li>
          {/* 앱 분석 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/appAnalysis") ? "active" : ""}`}>
            <Link href="/admin/appAnalysis">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <EqualizerOutlinedIcon />
                </i>
                <span className="menu-title">앱 분석</span>
              </a>
            </Link>
          </li>
          {/* 한줄 긋기 */}
          <li>
            <hr className="mx-1" />
          </li>
          {/* 사용자 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/userSetting") ? "active" : ""}`}>
            <Link href="/admin/userSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <PeopleAltOutlinedIcon />
                </i>
                <span className="menu-title">회원 관리</span>
              </a>
            </Link>
          </li>
          {/* 사용자 분석 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/userAnalysis") ? "active" : ""}`}>
            <Link href="/admin/userAnalysis">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <RecentActorsIcon />
                </i>
                <span className="menu-title">회원 분석</span>
              </a>
            </Link>
          </li>
          {/* 한줄 긋기 */}
          <li>
            <hr className="mx-1" />
          </li>

          {/* 고객센터 관리 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/customerService") ? "active" : ""}`}>
            <Link href="/admin/customerService">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <HelpOutlineRoundedIcon />
                </i>
                <span className="menu-title">고객센터 관리</span>
              </a>
            </Link>
          </li>

          {/* 권한 설정 */}
          <li className={`nav-item ${router.pathname.startsWith("/admin/authSetting") ? "active" : ""}`}>
            <Link href="/admin/authSetting">
              <a className="nav-link">
                <i className="menu-icon flex justify-center">
                  <TuneRoundedIcon />
                </i>
                <span className="menu-title">권한 설정</span>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    );
});

export default Sidebar;
